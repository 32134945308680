.container {
  margin-top: 100px;
}
.title {
  margin-bottom: 20px;
}
.form {
  width: 50%;
}
.button {
  margin-top: 10px;
}
.buttonLoading {
  margin: 0 10px 0 0;
  color: grey;
}
