.unsetMargin {
  margin: unset !important;
}

.paperContainer {
  margin-bottom: 30px;
  padding: 20px;
}

.posterInput {
  display: none;
}

.posterImage {
  height: 130px;
  width: 125px;
  object-fit: cover;
  border-radius: 8px;
}

.posterUploadContainer {
  height: 130px;
  width: 125px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: 1px dashed grey;
  margin: 10px 0;
}

.posterUploadContainer:hover {
  background-color: whitesmoke;
}

.verifyButton {
  margin-bottom: 20px;
}

.formButtons {
  margin: 0 10px !important;
  padding: 5px 20px !important;
}

.loader {
  color: grey;
  margin: 0px -10px 0px 10px;
}

.colorBlack {
  color: black !important;
}
