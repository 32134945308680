.appBar {
  justify-content: space-between;
  position: fixed;
}
.toolBar {
  justify-content: space-between;
  min-height: 50;
}
.tab {
  color: white;
  text-decoration: unset;
  margin: 0 5px;
  font-weight: 300;
}
.tab:hover {
  color: lightgray;
}
.tabSelected {
  font-weight: bold;
}
.menuIcon {
  color: white;
}
