.poster {
  height: 105px;
  width: 100px;
  object-fit: cover;
  margin: 5px;
  border-radius: 5px;
}

.loader {
  padding: 60px 0;
}

.searchContainer {
  padding: 2px;
  margin: 0 20px;
  border-radius: 5px;
  border: 1px solid #3f51b5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchInput {
  margin-left: 5px;
}
